<template>
  <DialogPanel
    class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-screen-xl"
  >
    <ModalHeader title="Complete document" @close="$emit('dismiss')" />

    <div class="p-4 sm:p-6 relative">
      <div @dblclick="debug = !debug" class="h-1 w-1 cursor-pointer absolute top-0 left-0"></div>
      <FormKit
        type="form"
        :actions="false"
        :incomplete-message="false"
        #default="{ value, state: { valid, loading } }"
        v-model="docForm"
        @submit="completeDocument"
      >
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-3">
          <DocumentImages
            :doc-id="doc.id"
            :image-paths="doc.imagePaths!"
            :image-urls="localImageUrls!"
            :scanning="!value!.categoryId && scanning"
          />
          <div>
            <FormKit
              type="autocomplete"
              name="categoryId"
              label="Document type"
              placeholder="Start typing..."
              :options="searchDocCategories as any"
              validation="required"
              :disabled="scanning || loading"
              @input="setDefaults as any"
              selection-appearance="option"
              data-test-id="document-type-dropdown"
            >
              <template #selection="{ option, classes }">
                <div :class="classes.selection">
                  <div class="formkit-option capitalize">
                    {{ $t(`docCategories.${option.value}.name`) }}
                  </div>
                </div>
              </template>
              <template #option="{ option }">
                <div class="formkit-option capitalize">
                  {{ $t(`docCategories.${option.value}.name`) }}
                  <span class="italic" v-if="option.matches?.length"
                    >({{ option.matches.join(', ') }})</span
                  >
                </div>
              </template>
            </FormKit>
            <div v-if="scanning">
              <InfoNote text="We are scanning your document"></InfoNote>
              <FormKit type="hidden" validation="required" />
            </div>
            <component
              :is="
                !!value?.categoryId
                  ? docCategories.get(value!.categoryId as string)?.component
                  : undefined
              "
              v-bind="{ value }"
            />
            <InfoNote
              v-if="!value?.categoryId && !scanning"
              text='Try to categorise your document accurately - start typing to see our suggestions. <a target="_blank" class="font-medium text-blue-700 underline hover:text-blue-600" href="https://profile-crewdentials.tawk.help/article/supported-document-types">Find out more</a>'
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-4 mt-10">
          <InfoNote color="amber" class="col-span-2 mb-4" v-if="corrupted"
            >Your file images may be corrupted. Please try refreshing or delete it and re-upload
            it!</InfoNote
          >
          <AppButton
            color="light"
            @clicked="$emit('dismiss')"
            data-test-id="complete-document-cancel-button"
            >Cancel</AppButton
          >
          <div class="h-[40px]" :class="valid ? '' : 'opacity-50'">
            <FormKit
              type="submit"
              :classes="{ input: 'h-[40px]' }"
              :disabled="corrupted || (!localImageUrls?.length && !imageUrls?.length)"
            />
          </div>
        </div>
        <div v-if="!scanning && debug">
          <pre v-if="!!scanResult?.text" class="mt-4 p-4 bg-gray-800 text-white rounded-lg"
            >{{ scanResult?.text }}
          </pre>
          <pre class="mt-4 p-4 bg-gray-800 text-white rounded-lg">{{ scanWithoutText }}</pre>
        </div>
      </FormKit>
    </div></DialogPanel
  >
</template>

<script setup lang="ts">
import { ref, onMounted, markRaw } from 'vue'
import type { Component } from 'vue'
import CertificateForm from '@/components/CertificateForm.vue'
import InfoNote from '@/components/InfoNote.vue'
import PassportForm from '@/components/PassportForm.vue'
import IdForm from '@/components/IdForm.vue'
import { useModalStore } from '@/stores/modal'
import Fuse from 'fuse.js'
import { DialogPanel } from '@headlessui/vue'
import saveCertificatesSeparately from '@/helpers/saveCertificatesSeparately'
import type {
  DocumentData,
  PassportFormData,
  OtherDocFormData,
  DocumentTypes,
  IdFormData
} from '@/types/types'
import { useI18n } from 'vue-i18n'
import ModalHeader from '@/components/ModalHeader.vue'
import AppButton from '@/components/AppButton.vue'
import { computed } from 'vue'
import { supabase } from '@/lib/supabaseClient'
import { useQueryClient } from '@tanstack/vue-query'
import { useDocumentScanQuery } from '@/queries/documentScan'
import { watch } from 'vue'
import axios from 'axios'
import OtherDocForm from '@/components/OtherDocForm.vue'
import DocumentImages from '@/components/DocumentImages.vue'

const debug = ref(false)

const props = defineProps<{
  localImageUrls?: string[]
  doc: Pick<DocumentData, 'id' | 'originalFilePaths' | 'imagePaths' | 'status' | 'data'>
}>()

const scanWithoutText = computed(() => {
  const { text, ...scan } = scanResult.value ?? {}
  return scan
})

const queryClient = useQueryClient()

const emit = defineEmits<{
  (event: 'dismiss', documents?: DocumentData[]): void
  (event: 'setBeforeDismiss', callback: (() => Promise<boolean>) | undefined): void
  (event: 'setAfterDismiss', callback: (() => Promise<boolean>) | undefined): void
}>()

const imageUrls = ref(props.localImageUrls)
const { presentLoader, dismissLoader, presentConfirm } = useModalStore()

const docForm = ref()

const completeDocument = async () => {
  let newDocuments: DocumentData[]

  const formValue = docForm.value!
  const type = formValue.categoryId as DocumentTypes
  if (type === 'CERTIFICATE') {
    const certificateForm = {
      id: props.doc.id,
      originalFilePaths: props.doc.originalFilePaths,
      imagePaths: props.doc.imagePaths,
      ...formValue,
      public: !!formValue.public
    }
    newDocuments = (await saveCertificatesSeparately(certificateForm)) as DocumentData[]
  } else {
    let doc
    if (type === 'PASSPORT') {
      doc = {
        categoryId: 'PASSPORT',
        documentRef: formValue.documentRef,
        issueDate: formValue.issueDate,
        expiryDate: formValue.expiryDate,
        passportNationality: formValue.passportNationality
      } as PassportFormData
    }
    if (type === 'OTHER') {
      doc = {
        categoryId: 'OTHER',
        name: formValue.name,
        issueDate: formValue.issueDate || null,
        expiryDate: formValue.expiryDate || null
      } as OtherDocFormData
    }
    if (type === 'ID') {
      doc = {
        categoryId: 'ID',
        documentRef: formValue.documentRef,
        name: formValue.name,
        issueDate: formValue.issueDate || null,
        expiryDate: formValue.expiryDate || null,
        issuerCountry: formValue.issuerCountry
      } as IdFormData
    }
    if (!doc) throw new Error('Unsupported type')
    const newDocument = await updateDoc({
      ...doc,
      status: 'COMPLETE',
      id: props.doc.id,
      public: !!formValue.public
    })
    newDocuments = [newDocument as DocumentData]
  }
  await queryClient.invalidateQueries({ queryKey: ['documents'] })
  emit('setAfterDismiss', undefined)
  emit('dismiss', newDocuments)
}

const updateDoc = async (updatedDoc: Partial<DocumentData> & { id: string }) => {
  const { id, ...updates } = updatedDoc
  return await supabase
    .from('Documents')
    .update(updates)
    .eq('id', id)
    .select()
    .single()
    .then(({ data }) => data)
}

const setDefaults = (typeId: string | undefined) => {
  docForm.value!.public = !!typeId ? docCategories.get(typeId)!.publicByDefault : false
}

const { data: scanResult, isLoading: scanning } = useDocumentScanQuery(props.doc)

watch(
  scanning,
  (value) => {
    if (!value && !!scanResult.value) {
      docForm.value = JSON.parse(
        JSON.stringify({
          documentRef: scanResult.value.documentRef,
          categoryId: scanResult.value.categoryId,
          certifications: !!scanResult.value.certifications ? scanResult.value.certifications : [],
          certificateIssuerId: scanResult.value.certificateIssuerId,
          expiryDate: scanResult.value.expiryDate,
          issueDate: scanResult.value.issueDate,
          issuerCountry: scanResult.value.issuerCountry,
          passportNationality: scanResult.value.passportNationality
        })
      )
    }
  },
  { immediate: true }
)

const corrupted = ref()

onMounted(async () => {
  emit('setAfterDismiss', async () => {
    const shouldKeep = await presentConfirm({
      title: `Do you want to keep this document?`,
      message: `You haven't completed this document, do you want to keep it?`,
      type: 'info',
      confirmText: `Yes, I'll complete it later`,
      cancelText: `No, delete it`
    })
    if (shouldKeep === false) {
      presentLoader()
      const { data } = await axios.post(`/crewdentialsProfile/deleteIncompleteDoc/${props.doc.id}`)
      dismissLoader()
      if (data) queryClient.invalidateQueries({ queryKey: ['documents'] })
    }
    return true
  })
  if (!props.localImageUrls) {
    try {
      imageUrls.value = await Promise.all(
        props.doc.imagePaths!.map(async (path: string) =>
          URL.createObjectURL((await supabase.storage.from('documents').download(path)).data!)
        )
      )
    } catch (e) {
      corrupted.value = true
    }
  }
})

const { tm } = useI18n()
const categories = tm('docCategories')

const formComponents: { [key: string]: Component } = {
  PASSPORT: markRaw(PassportForm),
  CERTIFICATE: markRaw(CertificateForm),
  ID: markRaw(IdForm),
  OTHER: markRaw(OtherDocForm)
}

const docCategories = new Map(
  Object.entries(categories).map(([key, value]) => {
    return [key, { ...value, id: key, component: formComponents[key], publicByDefault: true }]
  })
)

const fuse = new Fuse([...docCategories.values()], {
  keys: ['searchPhrases', 'name'],
  includeMatches: true
})

const searchDocCategories = ({ search }: any) => {
  if (!!search) {
    const results = fuse.search(search)
    if (results.length) {
      return results.map(({ item, matches }) => ({
        value: item.id,
        matches: matches!.filter(({ key }) => key === 'searchPhrases').map((match) => match.value)
      }))
    }
    return [...docCategories.values()]
      .filter(({ id }) => id === 'other')
      .map(({ id, name }) => ({ label: name, id, value: id }))
  }
  return [...docCategories.values()].map((docType) => ({
    value: docType.id
  }))
}
</script>
